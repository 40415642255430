import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/mainReducer";
import { Button, Flex } from "@chakra-ui/react";
import colorConfig from "../../config/colorConfig";
import html2canvas from "html2canvas";

export const DownloadAssinaturaButton = () => {
  const colaborador = useSelector(
    (state: RootState) => state.colaborador.colaborador
  );

  function formatarTelefone(numero: string): string {
    const num = numero.replace(/\D/g, "");
    return num.length === 11
      ? num.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
      : num.length === 10
      ? num.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
      : numero;
  }

  function capitalizarPalavras(texto: string): string {
    const preposicoes = new Set(["de", "da", "do", "dos", "das"]);

      return texto
        .toLowerCase()
        .split(" ")
        .map((palavra, index) => 
          preposicoes.has(palavra) && index !== 0
            ? palavra
            : palavra.charAt(0).toUpperCase() + palavra.slice(1)
        )
        .join(" ");
  }

  const gerarHtmlAssinatura = () => {
    const buscarCelularERamal = () => {
      return "<span><strong>Fone:</strong> (43) 3232-8000</span>";

      const celular = colaborador?.parceiro?.contatosProfissionais[0]?.celular;
      const ramal = colaborador?.parceiro?.usuario?.ramal;

      if (!celular) {
        return "";
      }

      let ramalTexto = "";
      if (ramal) {
        ramalTexto = ` | <strong>Ramal:</strong> ${ramal}`;
      }

      return `<span><strong>Fone:</strong> ${formatarTelefone(
        celular
      )}${ramalTexto}</span><br>`;
    };

    const buscarEmail = () => {
      return "";
      const email =
        colaborador?.parceiro?.usuario?.email ??
        colaborador?.parceiro?.contatosProfissionais[0]?.email;

      if (!email) {
        return "";
      }

      return `<span><strong>Contato:</strong> ${email.replaceAll(' ', '').trim()}</span><br>`;
    };

    return `
      <div style="font-family: Arial, sans-serif; width: 500px; padding: 20px 5px;">
        <table cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td>
              <strong>${capitalizarPalavras(
                colaborador.parceiro?.razao_social
              )}</strong><br>
              <span>${capitalizarPalavras((colaborador.funcao?.descricao ?? '').toUpperCase().replaceAll('SENIOR', '').replaceAll('PLENO', '').replaceAll('JUNIOR', ''))}</span><br>
              ${buscarCelularERamal()}
              ${buscarEmail()}
            </td>
          </tr>
          <tr>
            <td>
              <img src="https://minio.moinhoglobo.com.br/imagens-saerp/public/geral/footer-assinatura.png" 
                   alt="Footer Assinatura" 
                   style="max-width: 100%; height: auto;">
            </td>
          </tr>
        </table>
      </div>
    `;
  };

  const downloadAssinatura = async () => {
    try {
      const divTemp = document.createElement("div");
      divTemp.style.position = "absolute";
      divTemp.style.left = "-9999px";
      divTemp.innerHTML = gerarHtmlAssinatura();
      document.body.appendChild(divTemp);

      const canvas = await html2canvas(divTemp, {
        scale: 2,
        useCORS: true,
      });

      const image = canvas.toDataURL("image/jpeg", 1.0);

      const link = document.createElement("a");
      link.download = `assinatura-email-${
        colaborador.parceiro?.razao_social || "colaborador"
      }.jpg`;
      link.href = image;
      link.click();

      document.body.removeChild(divTemp);
    } catch (error) {
      console.error("Erro ao gerar assinatura:", error);
      alert("Ocorreu um erro ao gerar a assinatura. Tente novamente.");
    }
  };

  return (
    <Flex>
      <Button
        colorScheme="blackAlpha"
        onClick={downloadAssinatura}
        _hover={{ backgroundColor: colorConfig.primaryColor }}
        bg={colorConfig.primaryColor}
      >
        Baixar assinatura de E-mail
      </Button>
    </Flex>
  );
};
