import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HolerithRequestSenior } from "./types/holerithRequestSenior";

export type TGasto = {
  titulo: string;
  valor: number;
};

export type HoleriteResponse = {
  ultimasFolhasSaerp: Record<string, any>[];
  ultimasFolhasSenior: Record<string, any>[];
}

type TInitialState = {
  isLoading: boolean;
  error: string;
  holeriths: HoleriteResponse;
  isHolerithLoading: boolean;
  holerith: Record<string, any>;
  isFolhaPontoLoading: boolean;
  folhaPontob64: string;
  holerithSeniorB64: string;
  gastos: TGasto[];
};

const initialState: TInitialState = {
  isLoading: false,
  error: "",
  holeriths: {
    ultimasFolhasSaerp: [],
    ultimasFolhasSenior: [],
  },
  isHolerithLoading: false,
  holerith: {},
  isFolhaPontoLoading: false,
  holerithSeniorB64: '',
  folhaPontob64: "",
  
  gastos: [],
};

const holerithReducer = createSlice({
  name: "holerith",
  initialState,
  reducers: {
    requestHoleriths(state: TInitialState) {
      state.isLoading = true;
      state.error = "";
    },
    requestHolerithsSuccess(
      state: TInitialState,
      { payload }: PayloadAction<HoleriteResponse>
    ) {
      state.isLoading = false;
      state.holeriths = payload;
    },
    requestHolerithsError(
      state: TInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.isLoading = false;
      state.error = payload;
    },
    requestHolerith(state: TInitialState, _: PayloadAction<string>) {
      state.error = "";
      state.isHolerithLoading = true;
      state.holerith = {};
      state.folhaPontob64 = "";
    },
    requestHolerithSenior(state: TInitialState, _: PayloadAction<HolerithRequestSenior>) {
        state.isHolerithLoading = true;
        state.holerithSeniorB64 = '';
        state.error = '';
    },
    downloadHolerithSenior(state: TInitialState, _: PayloadAction<HolerithRequestSenior>) {
        state.isHolerithLoading = true;
        state.error = '';
    },
    downloadHolerithSeniorSuccess(state: TInitialState, _: PayloadAction<HolerithRequestSenior>) {
      state.isHolerithLoading = false;
      state.error = '';
  },
    requestHolerithSeniorSuccess(state: TInitialState, { payload }: PayloadAction<string>) {
      state.isHolerithLoading = false;
      state.holerithSeniorB64 = payload;
      state.error = '';
  },
    downloadHolerith(state: TInitialState, _: PayloadAction<string>) {
      state.error = "";
      state.isHolerithLoading = true;
      state.holerith = {};
    },
    downloadHolerithSuccess(state: TInitialState) {
      state.error = "";
      state.isHolerithLoading = false;
    },
    requestHolerithSuccess(
      state: TInitialState,
      { payload }: PayloadAction<Record<string, any>>
    ) {
      state.holerith = payload;
      state.isHolerithLoading = false;
    },
    requestHolerithError(
      state: TInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.error = payload;
      state.isHolerithLoading = false;
    },
    requestFolhaPonto(state: TInitialState, _: PayloadAction<string>) {
      state.error = "";
      state.folhaPontob64 = "";
      state.isFolhaPontoLoading = true;
      state.holerith = {};
    },
    downloadFolhaPonto(state: TInitialState, _: PayloadAction<string>) {
      state.error = "";
      state.folhaPontob64 = "";
      state.isFolhaPontoLoading = true;
    },
    downloadFolhaSuccess(state: TInitialState, _: PayloadAction<string>) {
      state.error = "";
      state.isFolhaPontoLoading = false;
    },
    requestFolhaPontoSuccess(
      state: TInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.folhaPontob64 = payload;
      state.isFolhaPontoLoading = false;
    },
    requestFolhaPontoError(
      state: TInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.error = payload;
      state.folhaPontob64 = "";
      state.isFolhaPontoLoading = false;
    },
    requestGastosConvenio(state, _: PayloadAction<string>) {
      state.error = "";
      state.isLoading = true;
      state.gastos = [];
    },
    gastosConvenioSuccess(state, { payload }: PayloadAction<TGasto[]>) {
      state.gastos = payload;
      state.isLoading = false;
    },
    gastosConvenioError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const holerithActions = holerithReducer.actions;

export default holerithReducer;
