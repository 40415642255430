import { HolerithPdf } from "./HolerithPdf";
import ReactPDF from "@react-pdf/renderer";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import download from "../../utils/download";
import { apiCall } from "../app/config";
import { holerithActions } from "./reducer";
import { HolerithRequestSenior } from "./types/holerithRequestSenior";

function* requestHolerithsWorker() {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: "/portalrh/ultimos-holeriths",
      method: "get",
    });
    console.log("Holeriths retornados", res.data);
    yield put(holerithActions.requestHolerithsSuccess(res.data));
  } catch (error: any) {
    console.log("error", error);
    yield put(
      holerithActions.requestHolerithsError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : JSON.stringify(error)
      )
    );
  }
}

function* requestHolerithWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: `/portalrh/holerith/${payload}`,
      method: "get",
    });
    console.log("Holerith retornado", res.data);
    yield put(holerithActions.requestHolerithSuccess(res.data));
  } catch (error: any) {
    console.log("error", error);
    yield put(
      holerithActions.requestHolerithError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : JSON.stringify(error)
      )
    );
  }
}

function* downloadHolerithWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: `/portalrh/holerith/${payload}`,
      method: "get",
    });
    const strPdf = ReactPDF.render(
      <HolerithPdf holerith={res.data} />,
      "holerith.pdf"
    );
    console.log("Holerith retornado", strPdf);
    // download(strPdf, "holerith.pdf", "application/pdf");

    yield put(holerithActions.downloadHolerithSuccess());
  } catch (error: any) {
    console.log("error", error);
    yield put(
      holerithActions.requestHolerithError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : JSON.stringify(error)
      )
    );
  }
}

function* requestFolhaPontoWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: `/portalrh/ponto/${payload}`,
      method: "get",
    });
    console.log("Ponto retornado", res.data);
    yield put(holerithActions.requestFolhaPontoSuccess(res.data));
  } catch (error: any) {
    console.log("error", error);
    yield put(
      holerithActions.requestFolhaPontoError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : JSON.stringify(error)
      )
    );
  }
}

function* downloadFolhaPontoWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: `/portalrh/ponto/${payload}`,
      method: "get",
    });
    console.log("Ponto retornado", res.data);
    download(
      `data:application/pdf;base64,${res.data}`,
      `${payload}.pdf`,
      "application/pdf"
    );
    yield put(holerithActions.downloadFolhaSuccess(res.data));
  } catch (error: any) {
    console.log("error", error);
    yield put(
      holerithActions.requestFolhaPontoError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : JSON.stringify(error)
      )
    );
  }
}


function* requestHolerithSeniorWorker({ payload }: PayloadAction<HolerithRequestSenior>) {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: `/portalrh/holerith-senior`,
      method: "post",
      data: payload,
    });
    console.log("Holerith senior retornado", res.data);
    yield put(holerithActions.requestHolerithSeniorSuccess(res.data));
  } catch (error: any) {
    console.log("error", error);
    yield put(
      holerithActions.requestHolerithError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : (error.response?.data?.error ? error.response?.data?.error : JSON.stringify(error))
      )
    );
  }
}


function* downloadHolerithSeniorWorker({ payload }: PayloadAction<HolerithRequestSenior>) {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: `/portalrh/holerith-senior`,
      method: "post",
      data: payload,
    });
    console.log("Holerith senior retornado", res.data);

    download(
      `data:application/pdf;base64,${res.data}`,
      `${payload.anomes}-${payload.tipoFolha}.pdf`,
      "application/pdf"
    );
    yield put(holerithActions.downloadHolerithSeniorSuccess(payload));
  } catch (error: any) {
    console.log("error", error);
    yield put(
      holerithActions.requestHolerithError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : (error.response?.data?.error ? error.response?.data?.error : JSON.stringify(error))
      )
    );
  }
}

function* requestGastosWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: `/portalrh/gastos-convenio/${payload}`,
      method: "get",
    });
    console.log("Gastos retornado", res.data);
    yield put(holerithActions.gastosConvenioSuccess(res.data));
  } catch (error: any) {
    console.log("error", error);
    yield put(
      holerithActions.gastosConvenioError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : JSON.stringify(error)
      )
    );
  }
}

export function* holerithSaga() {
  yield all([
    takeLatest("holerith/requestHoleriths", requestHolerithsWorker),
    takeLatest("holerith/requestHolerith", requestHolerithWorker),
    takeLatest("holerith/downloadHolerith", downloadHolerithWorker),
    takeLatest("holerith/requestFolhaPonto", requestFolhaPontoWorker),
    takeLatest("holerith/downloadFolhaPonto", downloadFolhaPontoWorker),
    takeLatest("holerith/requestGastosConvenio", requestGastosWorker),
    takeLatest('holerith/requestHolerithSenior', requestHolerithSeniorWorker),
    takeLatest('holerith/downloadHolerithSenior', downloadHolerithSeniorWorker)
  ]);
}
